import React, { ReactElement } from 'react';
import styled from '@emotion/styled';

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  font-size: ${({ theme }) => theme.fontSize.medium};
  line-height: 31px;
  margin-bottom: 1.2rem;
  display: flex;
  flex-direction: row;
  span {
    margin-left: 1.2rem;
  }
`;
interface IDescription {
  data: { item: string; image: string }[];
}

const Description = ({ data }: IDescription): ReactElement => (
  <List>
    {data.map(({ item, image }, i) => (
      <ListItem key={i}>
        <img src={image} height="31" />
        <span dangerouslySetInnerHTML={{ __html: item }} />
      </ListItem>
    ))}
  </List>
);

export default Description;
