import React, { ReactElement } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'src/components/common/Button';
import {
  Wrapper,
  Container,
  Content,
  Label,
  Input,
  Title,
  ButtonWrapper,
} from './styles';

const ContactForm = (): ReactElement => (
  <Wrapper noGutters>
    <Container>
      <Content>
        <Title>
          Hi! <br />
          We’d love to hear from you.{' '}
        </Title>
        <Form>
          <Row>
            <Col lg="6">
              <Form.Group controlId="formFullName">
                <Label>Full name</Label>
                <Input type="name" />
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group controlId="formBasicEmail">
                <Label>Email</Label>
                <Input type="email" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="formMessage">
                <Label>Message</Label>
                <Input as="textarea" type="text" rows={4} />
              </Form.Group>
            </Col>
          </Row>
          <ButtonWrapper>
            <Button
              type="submit"
              paddingLeft="2.4rem"
              paddingRight="2.4rem"
            >
              Send now
            </Button>
          </ButtonWrapper>
        </Form>
      </Content>
    </Container>
  </Wrapper>
);

export default ContactForm;
