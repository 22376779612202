import React, { ReactElement } from 'react';
import Layout from '../components/common/Layout';
import { MetaData } from '../components/common/Meta';
import { HeroSection } from 'src/components/HomeContent';
import {
  Description,
  ContactForm,
} from 'src/components/ContactContent';

interface IContact {
  location: {
    pathname: string;
  };
}

const Contact = ({ location }: IContact): ReactElement => {
  return (
    <>
      <MetaData location={location} />
      <Layout isHome={false}>
        <HeroSection data={contactPageData.hero}>
          <Description data={contactPageData.contact} />
        </HeroSection>
        <ContactForm />
      </Layout>
    </>
  );
};

const contactPageData = {
  hero: {
    title: 'Contact Us',
    description:
      'Alimosho is a Local Government Area in the Ikeja Division, Lagos State, Nigeria It is the largest local government in Lagos, with 1,288,714 inhabitants, according to the official 2006 Census (however, the Lagos State Government disputes the official Census figures and claims a population within the LGA of more than 2 million residents). It has now been subdivided between several',
    image: '/images/contact.jpg',
  },
  contact: [
    {
      item: 'stackwalker@mail.com',
      image: '/images/mail.png',
    },
    {
      item: '42, Mongomery road Sabo yaba, <br> Lagos Nigeria',
      image: '/images/address.png',
    },
    {
      item: '+2348027272720',
      image: '/images/phone.png',
    },
    {
      item: '01 484692',
      image: '/images/phone.png',
    },
  ],
};

export default Contact;
