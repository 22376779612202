import styled from '@emotion/styled';
import BootstrapRow from 'react-bootstrap/Row';
import BootstrapContainer from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

export const Wrapper = styled(BootstrapRow)`
  background-color: ${({ theme }) => theme.backgroundGray};
  padding-top: 4rem;
  padding-bottom: 4rem;
`;

export const Container = styled(BootstrapContainer)`
  padding: 0;
`;

export const Content = styled.div`
  padding: 1rem 10rem;
  @media (max-width: 962px) {
    padding: 1px 2rem;
  }
`;

export const Title = styled.h1`
  font-size: ${({ theme }) =>
    theme.sections.headlines.large.fontSize};
  font-weight: ${({ theme }) =>
    theme.sections.headlines.large.fontWeight};
  color: ${({ theme }) => theme.sections.headlines.large.color};
  margin-bottom: 3rem;
  padding: 0;
`;

export const Label = styled(Form.Label)`
  font-weight: bold;
`;

export const Input = styled(Form.Control)`
  background-color: ${({ theme }) => theme.backgroundGray};
  border: none;
  border-bottom: 2px solid #979797;
  border-radius: unset;
  :hover,
  :focus {
    background-color: ${({ theme }) => theme.backgroundGray};
  }
`;

export const ButtonWrapper = styled.div`
  float: right;
  margin-top: 2rem;
`;
